// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const learneddev = {
  //aws_app_analytics: 'disable',
  main_color_1: "#0f80a4",
  main_color_2: "#f18121",
  platform_main_theme: "linear-gradient(#0f80a4, #0f80a4)",
  button_color_web: "#0f80a4",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "m99fvglhn1",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://m99fvglhn1.execute-api.us-east-1.amazonaws.com/LEARNED_DEV",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "m99fvglhn1",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://m99fvglhn1.execute-api.us-east-1.amazonaws.com/LEARNED_DEV",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cloud_logic_custom_name_E: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:55a63979-c796-4fc3-8fc8-140fae5debf6",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-dev-learned",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1if2pjtoq2sst.cloudfront.net",
  DOMAIN: "d1if2pjtoq2sst.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  //aws_mobile_analytics_app_id: 'f085468f71664440ba6153ccb1036a9a',
  //aws_mobile_analytics_app_region: 'us-east-1',
  //aws_project_id: '5b55d3ef-e764-4657-b5c6-c0b023e8790e',
  //aws_project_name: 'EnhanzedRN',
  aws_project_region: "us-east-1",
  //aws_push_apns: 'enable',
  //aws_push_pinpoint: 'enable',
  aws_resource_name_prefix: "learned-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_kBQrgXxdu",
  aws_user_pools_web_client_id: "1pd107l82ghrr0a8ptmgi9njlg",
  aws_cloud_logic_custom_endpoint:
    "https://m99fvglhn1.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "LEARNED",
  aws_org_id_E: "LEARNED",
  aws_user_pools_id_E: "us-east-1_kBQrgXxdu",
  aws_user_pools_web_client_id_E: "1pd107l82ghrr0a8ptmgi9njlg",
  //aws_mobile_analytics_app_id_E: 'f085468f71664440ba6153ccb1036a9a',
  aws_content_delivery_cloudfront_domain_E: "d1if2pjtoq2sst.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:55a63979-c796-4fc3-8fc8-140fae5debf6",
  aws_cloud_logic_custom_endpoint_E:
    "https://m99fvglhn1.execute-api.us-east-1.amazonaws.com/LEARNED_DEV",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "m99fvglhn1",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://m99fvglhn1.execute-api.us-east-1.amazonaws.com/LEARNED_DEV",
      region: "us-east-1",
    },
  ],
};

// Start of learned prod instance
const learnedProd = {
  main_color_1: "#0f80a4",
  main_color_2: "#f18121",
  platform_main_theme: "linear-gradient(#0f80a4, #0f80a4)",
  button_color_web: "#0f80a4",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "pek9bq6l8c",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:e0aebaeb-5794-4946-bd55-71645255ad17",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1erhpqukc9wx5.cloudfront.net",
  DOMAIN: "d1erhpqukc9wx5.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_0PiIc3aI3",
  aws_user_pools_web_client_id: "4cbob30besspi51nbfpt75qqhj",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "LEARNED",
};

// Start of jss Mysore prod instance
const jssmysprod = {
  main_color_1: "#01458E",
  main_color_2: "#E35F14",
  platform_main_theme: "linear-gradient( #01458E,  #01458E)",
  button_color_web: " #01458E",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "pek9bq6l8c",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:e025a8e3-00cb-4df3-92d9-f4f89d8f0d83",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d329yd2lem62zp.cloudfront.net",
  DOMAIN: "d329yd2lem62zp.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_n99K6wQwG",
  aws_user_pools_web_client_id: "3m9fpqrqroflcnktsu4gvk1ddd",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHERMYSORE",
};

// Start of jss Ooty prod
const jssootyprod = {
  main_color_1: "#01458E",
  main_color_2: "#E35F14",
  platform_main_theme: "linear-gradient( #01458E,  #01458E)",
  button_color_web: " #01458E",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "pek9bq6l8c",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:c2c34952-f862-4c5c-9c10-138e9a731aae",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "decrt8qnl2rk0.cloudfront.net",
  DOMAIN: "decrt8qnl2rk0.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_yPlehdnA5",
  aws_user_pools_web_client_id: "1tjhins64b1sar2v9bpj964gc2",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHEROOTY",
};

// Start of T John dev instance
const tJohnprod = {
  main_color_1: "#00529E",
  main_color_2: "#DA251C",
  platform_main_theme: "linear-gradient(#00529E, #00529E)",
  button_color_web: "#00529E",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "pek9bq6l8c",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:79bc7671-28d6-4cea-93e3-5f3716135dd7",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d39amklve0smgh.cloudfront.net",
  DOMAIN: "d39amklve0smgh.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_FxkeKzC99",
  aws_user_pools_web_client_id: "19v2f9bm0hnb0v845n44d7jt3l",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "TJOHN",
};

// Start of T John dev instance
const jssMauritius = {
  main_color_1: " #28166F",
  main_color_2: "#DA251C",
  platform_main_theme: "linear-gradient( #28166F,  #28166F)",
  button_color_web: " #28166F",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "pek9bq6l8c",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:13b8f0f8-e357-47a3-a7ba-5cf10b82da51",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1u9w9poxvz87v.cloudfront.net",
  DOMAIN: "d1u9w9poxvz87v.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_tAiXe9Y83",
  aws_user_pools_web_client_id: "79lcfi0atsb414sv9oo77mgag3",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHERMAURITIUS",
};

// Start of T John dev instance
const jssNoida = {
  main_color_1: "#E7493A",
  main_color_2: "#3B5998",
  platform_main_theme: "linear-gradient(#E7493A, #E7493A)",
  button_color_web: "#E7493A",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "pek9bq6l8c",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:a1f9b82c-cfb1-41a4-bd4f-36d5e5df5fca",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2yx4k7ijlk17h.cloudfront.net",
  DOMAIN: "d2yx4k7ijlk17h.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_tI1BDptXa",
  aws_user_pools_web_client_id: "1b9aaah9d5mp1nhrnqmu3mm65n",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JSSAHERNOIDA",
};

// Start of T John dev instance
const saradaVillas = {
  main_color_1: "#081455",
  main_color_2: "#42B0FF",
  platform_main_theme: "linear-gradient(#081455, #081455)",
  button_color_web: "#081455",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:c0c11eda-620c-476b-9eea-2650fd93ff1a",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2zrg5m4p3ti7g.cloudfront.net",
  DOMAIN: "d2zrg5m4p3ti7g.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_gbfB1cSbH",
  aws_user_pools_web_client_id: "3qa3onfl00nhaq5t7nct26q4og",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "SARADAVILAS",
};

// Start of NieIT prod instance
const nieit = {
  main_color_1: "#3F4094",
  main_color_2: "#3DB166",
  platform_main_theme: "linear-gradient(#3F4094, #3F4094)",
  button_color_web: "#3F4094",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:51745abf-9fcf-4b7e-a781-191aa111de9e",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d33j0q3ff48itq.cloudfront.net",
  DOMAIN: "d33j0q3ff48itq.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_tY9jQ3w4P",
  aws_user_pools_web_client_id: "4eg54rnbk9pbj9l3ikmlqsu7kh",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "NIEIT",
};

// Start of sdmit prod instance
const sdmit = {
  main_color_1: "#0f80a4",
  main_color_2: "#FF1949",
  platform_main_theme: "linear-gradient(#0f80a4, #0f80a4)",
  button_color_web: "#0f80a4",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:9aa214be-1886-4626-b951-1996d68dbd44",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2ofkkgvyysxcg.cloudfront.net",
  DOMAIN: "d2ofkkgvyysxcg.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_vYtK2JOwR",
  aws_user_pools_web_client_id: "9nkfl58upn2pe1o97q69a62el",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "SDMIT",
};

// Start of Adhichunchanagiri College of Pharmacy prod instance
const adhigiri = {
  main_color_1: "#37306F",
  main_color_2: "#A72F2E",
  platform_main_theme: "linear-gradient(#37306F, #37306F)",
  button_color_web: "#37306F",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:a55b3148-eea2-4948-af9e-c872ef07c6ec",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1xut4e7fr5g4q.cloudfront.net",
  DOMAIN: "d1xut4e7fr5g4q.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_gRC8iiA5M",
  aws_user_pools_web_client_id: "4nmkd32i8kqj38p9lfm2jpdqgo",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "ADIGIRI",
};

// Start of queens school instance
const qsd = {
  main_color_1: "#ffb81c",
  main_color_2: "#ffb81c",
  platform_main_theme: "linear-gradient(#FFB81C, #FFB81C)",
  button_color_web: "#ffb81c",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:baf69c07-9169-46e2-8a5c-c30358d1d9e9",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d11cwfceogqp5g.cloudfront.net",
  DOMAIN: "d11cwfceogqp5g.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_rDlmBPKg5",
  aws_user_pools_web_client_id: "3jg0aeqruqfuhqc1ssr2l33ppp",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "QSD",
};

// Start of Bharathi College of Pharmacy prod instance
const bcop = {
  main_color_1: "#2D7FC7",
  main_color_2: "#29a22c",
  platform_main_theme: "linear-gradient(#2D7FC7, #2D7FC7)",
  button_color_web: "#2D7FC7",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:eacb915e-9d35-4b21-8b70-515a9532a156",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1r9hhv4z5c728.cloudfront.net",
  DOMAIN: "d1r9hhv4z5c728.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_dQpv5Qkej",
  aws_user_pools_web_client_id: "17fi35gdiv43rb5japaejsh14p",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "BCOP",
};

// Start of Abhinava Bharathi College of Pharmacy prod instance
const abcop = {
  main_color_1: "#48487F",
  main_color_2: "#E35F14",
  platform_main_theme: "linear-gradient(#48487F, #48487F)",
  button_color_web: "#48487F",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:2d7ec92e-cb0d-4252-a3dc-38574c1939f7",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2pe6vqauiph9.cloudfront.net",
  DOMAIN: "d2pe6vqauiph9.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_q8YgT7072",
  aws_user_pools_web_client_id: "3bns1cumv5dj8130a9jimp5a2f",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "ABCOP",
};

// Start of GGN Khalsa College of Pharmacy prod instance
const ggnkc = {
  main_color_1: "#0D2643",
  main_color_2: "#81CA00",
  platform_main_theme: "linear-gradient(#0D2643, #0D2643)",
  button_color_web: "#0D2643",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:eacb915e-9d35-4b21-8b70-515a9532a156",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1r9hhv4z5c728.cloudfront.net",
  DOMAIN: "d1r9hhv4z5c728.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_dQpv5Qkej",
  aws_user_pools_web_client_id: "17fi35gdiv43rb5japaejsh14p",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "BCOP",
};
const uok = {
  main_color_2: "#333333",
  main_color_1: "#56405E",
  platform_main_theme: "linear-gradient(#56405E, #56405E)",
  button_color_web: "#56405E",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:f9dedd53-5463-46d6-984c-7210ae829bb0",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1cy14lba025je.cloudfront.net",
  DOMAIN: "d1cy14lba025je.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_EbO5nZ1xE",
  aws_user_pools_web_client_id: "58k8s46cs52i1sthj914htmsih",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "UOK",
};

const Rajputana = {
  main_color_2: "#333333",
  main_color_1: "#20B876",
  platform_main_theme: "linear-gradient(#20B876, #20B876)",
  button_color_web: "#20B876",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:6d8df232-447e-4a06-a2d9-9a429582cd17",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d1c6c2kmy8kitp.cloudfront.net",
  DOMAIN: "d1c6c2kmy8kitp.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_zB6wN060C",
  aws_user_pools_web_client_id: "49p96fnsifb31siep9r9daislv",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "RAJPUTANA",
};
const ALWARPC = {
  main_color_1: "#2E3192",
  main_color_2: "#E9312F",
  platform_main_theme: "linear-gradient(#2E3192, #2E3192)",
  button_color_web: "#2E3192",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:4df4d3de-c291-42be-b470-c1a256e9997d",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2ima15mtg2280.cloudfront.net",
  DOMAIN: "d2ima15mtg2280.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_Avz9kpYaR",
  aws_user_pools_web_client_id: "joj27o7894jm0g7ll56shfbb8",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "ALWARPC",
};
const MCOP = {
  main_color_1: "#01733A",
  main_color_2: "#B12639",
  platform_main_theme: "linear-gradient(#01733A, #01733A)",
  button_color_web: "#01733A",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "9ocpxowhe2",
      name: "LEARNED-PLATFORM",
      description: "",
      endpoint:
        "https://pek9bq6l8c.execute-api.us-east-1.amazonaws.com/LEARNED_PROD",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "LEARNED-PLATFORM",
  aws_cognito_identity_pool_id:
    "us-east-1:6b10f021-a77c-404b-a1af-97c50018e0d4",
  aws_cognito_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d3u6nec2psltji.cloudfront.net",
  DOMAIN: "d3u6nec2psltji.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_e8FJIlR8S",
  aws_user_pools_web_client_id: "16ggrivc7e2eavsf2bifhcv13g",
  aws_cloud_logic_custom_endpoint:
    "https://0g4d0icu51.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "MCOP",
};
export default bcop;
